import React from 'react';
import './Notfound.css';

function Notfound() {
  return (
    <div className="not-found">
    	<div className="greeter mx-auto text-center">
		  <h1 className="display-4"><span className="wow">Oops...</span></h1>
		  <div className="lead d-flex justify-content-center">
		  	<div className="col-7 text-center">
		  		<p>You are lost :(</p>
		  		<p>Make sure you are permitted to access this page.</p>
		  	</div>
		  </div>
		</div>
    </div>
  );
}

export default Notfound;