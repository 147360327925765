import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Career from './components/Career';
// import Blog from './components/Blog';
// <Route path='/blog' component={Blog}/>
import Contact from './components/Contact';
import Notfound from './components/Notfound';
import { AnimatedSwitch } from 'react-router-transition';

function App() {
  return (
  	<div>
	  	<Navigation />
	  	<div className="content">
	  		<AnimatedSwitch
		      atEnter={{ opacity: 0.5 }}
		      atLeave={{ opacity: 0.5 }}
		      atActive={{ opacity: 1 }}
		    >
		       <Route exact path='/' component={Home}/>
		       <Route path='/about' component={About}/>
		       <Route path='/services' component={Services}/>
		       <Route path='/career' component={Career}/>
		       <Route path='/contact' component={Contact}/>
		       <Route component={Notfound} />
		    </AnimatedSwitch>
	    </div>
	    <Footer />
    </div>
  );
}

export default App;
