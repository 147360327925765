import React from 'react';
import LogoMsa from '../images/logo-msa.png';
import './Navigation.css';
import { Link, NavLink } from 'react-router-dom';
// <NavLink activeClassName="is-active" className="pl-4 pr-4 btn btn-sm font-weight-bold aauxpro" to='/blog'>Blog</NavLink>

export default class Navigation extends React.Component {
  render() {
    return (
      <div className="navigation fixed-top d-flex flex-column flex-md-row align-items-center px-md-4 mb-3 pb-2">
        <Link to="/" className="my-0 mr-md-auto"><img src={LogoMsa} className="logo" alt="msa-top-logo"/></Link>
        <nav className="my-2 my-md-0 mr-md-3 text-center pt-1">
          <NavLink exact activeClassName="is-active" className="pl-4 pr-4 btn btn-sm font-weight-bold aauxpro" to='/'>Home</NavLink>
          <NavLink activeClassName="is-active" className="pl-4 pr-4 btn btn-sm font-weight-bold aauxpro" to='/about'>About Us</NavLink>
          <NavLink activeClassName="is-active" className="pl-4 pr-4 btn btn-sm font-weight-bold aauxpro" to='/services'>Services</NavLink>
          <NavLink activeClassName="is-active" className="pl-4 pr-4 btn btn-sm font-weight-bold aauxpro" to='/career'>Career</NavLink>
          <NavLink className="pl-4 pr-4 btn btn-sm bg-msa-yellow text-dark font-weight-bold aauxpro" to='/contact'>Contact Us</NavLink>
        </nav>
      </div>
    );
  }
}