import React from 'react';
import ImgBgManaged from '../images/services/bg-managed-service.jpg';
import ImgBgImplement from '../images/services/bg-implementation.jpg';
import './Services.css';

function Services() {
  return (
    <div className="services">
    	<div className="greeter d-flex justify-content-center align-items-center mx-auto text-center">
    		<div className="col-12">
				  <h1 className="display-4 josefinsans font-weight-bold">Our <span className="wow">Services</span></h1>
				  <div className="lead d-flex justify-content-center">
				  	<div className="col-xs-11 col-sm-10 col-md-8 col-lg-6 col-xl-6 mb-5 text-center aauxpro">
				  		<p>
				  		Simplify and integrate your HR processes with a complete Human Resources Information System (HRIS).
				  		</p>
				  		<p>
				  		Let MSA show you how you can use these systems to your advantage and guide you throughout the process.
				  		</p>
				  	</div>
				  </div>
		  	</div>
		</div>
		<div className="service text-justify">
			<div className="d-flex justify-content-center pt-5 mb-5" id="managed-service">
				<div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 bg-req p-0 d-none d-md-block bg-grey">
							<img src={ImgBgManaged} alt="Managed Service" />
						</div>
						<div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 req-box d-flex align-items-center service-box">
							<div>
								<h4 className="display-6 mb-4 josefinsans font-weight-bold"><span className="wow">01.</span> Managed Service</h4>
								<p className="mb-4 aauxpro">
									MSA provides, enhances, and supports your SAP HCM End-to-End Process. We make sure that by using the latest HRIS technologies, your company will deliver the most efficient and effective core HR system—therefore driving exceptional results across your business.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center pt-5 mb-5" id="implementation">
				<div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 bg-req p-0 d-none d-md-block bg-grey">
							<img src={ImgBgImplement} alt="Implementation" />
						</div>
						<div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 req-box d-flex align-items-center service-box">
							<div>
								<h4 className="display-6 mb-4 josefinsans font-weight-bold"><span className="wow">02.</span> Implementation</h4>
								<p className="mb-4 aauxpro">
									Improve the employee experience with SAP SuccessFactors. Through comprehensive Cloud-Based Human Resources solutions, SuccessFactors eliminates the need for hardware, maintenance, and costly upgrade issues. Gain better control over your Human Resources with a fully mobile solution!
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<div className="col-xs-10 col-sm-10 col-md-8 col-lg-8 col-xl-6 text-center aauxpro">
					Our services are supported by the latest HRIS technology as well as the dedication of our expert consultants and developers, all working in favor of your business growth.
				</div>
			</div>
		</div>
    </div>
  );
}

export default Services;