import React from 'react';
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImgManagedService from '../images/home/managed-service.png';
import ImgImplementation from '../images/home/implementation.png';
import ImgPupukIndo from '../images/home/logo-pupuk-indo.jpg';
import ImgPosIndo from '../images/home/logo-pos-indo.jpg';
import ImgSemenIndo from '../images/home/logo-semen-indo.jpg';
import ImgKepLand from '../images/home/logo-keppel-land.jpg';
import ImgAngPura from '../images/home/logo-angkasa-pura.jpg';
import ImgBtn from '../images/home/logo-btn.jpg';
import ImgMandiri from '../images/home/logo-mandiri.jpg';
import ImgKai from '../images/home/logo-kai.png';
import ImgXlAxiata from '../images/home/logo-xl-axiata.png';
import ImgBgRequest from '../images/home/bg-request.jpg';
import Slider from "react-slick";

import { Link as LinkScroll } from 'react-scroll';

function Home() {
	var settings = {
      dots: true,
      infinite: false,
      speed: 700,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 0.5,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            infinite: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            infinite: true
          }
        }
      ]
    };
  return (
  	<div className="home">
  		<div className="greeter d-flex justify-content-center align-items-center mx-auto text-center">
  			<div className="col-12">
				  <h1 className="display-4 josefinsans font-weight-bold">Your Business Partner<br/>in <span className="wow">HR Solutions</span></h1>
				  <div className="lead d-flex justify-content-center">
				  	<div className="col-xs-11 col-sm-10 col-md-10 col-lg-8 col-xl-6 text-center aauxpro font-weight-bold">
				  		Get the most out of your human capital as your business grows.
				  	</div>
				  </div>
				  <div className="lead d-flex justify-content-center">
					<LinkScroll activeClass="active" className="btn btn-lg btn-light text-dark aauxpro font-weight-bold cursor-hand" to="services" spy={true} smooth={true} offset={-70} duration= {500}>Learn More</LinkScroll>
				  </div>
			  </div>
			</div>
			<div className="services mx-auto text-center" id="services">
			  <h2 className="display-5 mb-5 josefinsans font-weight-bold">Our <span className="wow">Services</span></h2>
			  <Slider {...settings} className="text-justify">
		        <div>
		        	<div className="box shadow p-3 mb-5 bg-white rounded">
			          <h4 className="ml-2 josefinsans font-weight-bold">Managed Service</h4>
			          <div className="row p-2">
			          	<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 text-center">
			          		<img src={ImgManagedService} className="img-fluid" alt="Managed Service" />
			          	</div>
			          	<div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
			          		<p className="mt-3 aauxpro">
			          			MSA provides, enhances, and supports your SAP HCM End-to-End Process.
			          		</p>
			          		<a className="btn btn-lg discover aauxpro font-weight-bold" href="/services#managed-service">Discover</a>
			          	</div>
			          </div>
			        </div>
		        </div>
		        <div>
		          	<div className="box shadow p-3 mb-5 bg-white rounded">
			          <h4 className="ml-2 josefinsans font-weight-bold">Implementation</h4>
			          <div className="row p-2">
			          	<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 text-center">
			          		<img src={ImgImplementation} className="img-fluid" alt="Implementation" />
			          	</div>
			          	<div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
			          		<p className="mt-3 aauxpro">
			          			Gain better control over your Human Resources with SAP SuccessFactors.
			          		</p>
			          		<a className="btn btn-lg discover aauxpro font-weight-bold" href="/services#implementation">Discover</a>

			          	</div>
			          </div>
			        </div>
		        </div>
		      </Slider>
			</div>
			<div className="trusted mx-auto text-center bg-white" id="portfolio">
				<h2 className="display-5 mb-5 josefinsans font-weight-bold">Our <span className="wow">Portfolio</span></h2>
				<div className="row d-flex justify-content-center m-0">
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgPupukIndo} alt="Pupuk Indonesia" />
					</div>
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgAngPura} alt="Angkasa Pura" />
					</div>
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgMandiri} alt="Mandiri" />
					</div>
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgSemenIndo} alt="Semen Indonesia" />
					</div>
				</div>
				<div className="row d-flex justify-content-center m-0">
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgBtn} alt="BTN" />
					</div>
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgPosIndo} alt="Pos Indonesia" />
					</div>
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgKepLand} alt="Keppel Land" />
					</div>
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgKai} alt="Kereta Api Indonesia" />
					</div>
				</div>
				<div className="row d-flex justify-content-center m-0">
					<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-3 mb-2 d-flex align-items-center justify-content-center">
						<img className="img-fluid" src={ImgXlAxiata} alt="XL Axiata" />
					</div>
				</div>
			</div>
			<div className="request">
				<div className="row m-0">
					<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 bg-req p-0 d-none d-md-block">
						<img src={ImgBgRequest} alt="Request" />
					</div>
					<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-7 req-box d-flex align-items-center">
						<div>
							<h4 className="display-6 mb-4 josefinsans font-weight-bold">Request for a <span className="wow">FREE Consultation</span></h4>
							<p className="mb-4 aauxpro">
								Facing HR issues? Take a step in the right direction.<br/>
								Get a Consultant.
							</p>
							<a href="https://api.whatsapp.com/send?phone=6281284038431&text=Hi MSA" className="btn btn-success mr-4 mb-2 aauxpro font-weight-bold">Whatsapp</a>
							<a className="btn btn-light mr-4 mb-2 aauxpro font-weight-bold" href="mailto:admin@msa-id.com?Subject=Hello%20MSA" target="_top">Email</a>
						</div>
					</div>
				</div>
			</div>
  	</div>
  );
}

export default Home;