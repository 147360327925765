import React from 'react';
import './About.css';
import ImgWhyMsa from '../images/aboutus/why-msa.png';
import ImgTrustworthy from '../images/aboutus/trust.png';
import ImgProfessional from '../images/aboutus/professional.png';
import ImgFastRespond from '../images/aboutus/fast.png';
import ImgSimple from '../images/aboutus/goal.png';
import ImgAndi from '../images/aboutus/team/andi.jpg';
import ImgImam from '../images/aboutus/team/imam.jpg';
import ImgPanji from '../images/aboutus/team/panji.jpg';
import ImgArnold from '../images/aboutus/team/arnold.jpg';
import ImgFauzi from '../images/aboutus/team/fauzi.jpg';

function About() {
  return (
    <div className="about">
    	<div className="greeter d-flex justify-content-center align-items-center mx-auto text-center" id="goals">
    		<div className="col-12">
				  <h1 className="display-4 josefinsans font-weight-bold">Our <span className="wow">Goals</span></h1>
				  <div className="lead d-flex justify-content-center">
				  	<div className="col-xs-11 col-sm-10 col-md-8 col-lg-6 col-xl-6 mb-2 text-center aauxpro">
				  		Resources technical strategies, integration, and support in appropriate HR information systems (HRIS) through ERP SAP On-Premise HCM Solutions.
				  	</div>
				  </div>
				  <div className="lead d-flex justify-content-center">
				  	<div className="desc col-xs-11 col-sm-10 col-md-10 col-lg-8 col-xl-8 mb-3 text-center aauxpro">
				  		In this modern era, a sense of digital readiness must be implanted within the organization to ensure that your company’s human capital is suitable for the workforce of today and the future. WIth MSA’s experience, deep insight, and technology, we can help transform your human capital’s capabilities into a competitive advantage that works in support of your business strategy.
				  	</div>
				  </div>
			  </div>
			</div>
			<div className="why mx-auto text-center">
				<h2 className="display-5 mb-5 pl-4 pr-4 text-dark-blue josefinsans font-weight-bold">Your company's path towards efficient and competitive human capital starts here.</h2>
				<div className="d-flex justify-content-center text-justify">
					<div className="box col-xs-9 col-sm-8 col-md-8 col-lg-7 shadow p-4 mb-5 bg-white rounded">
	          <div className="row">
	          	<div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center">
	          		<img src={ImgWhyMsa} className="img-fluid" alt="why MSA" />
	          	</div>
	          	<div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
	          		<h4 className="josefinsans font-weight-bold">Why MSA?</h4>
	          		<p className="mt-3 aauxpro">
	          			MSA understands the importance of having an effective and efficient human capital system for a sustainable, driven organization—and we know the ways of achieving it. Our company was started by a group of people who have years of experience in the Human Resources field, with vast knowledge from the day-to-day activities of HR to the technology needed to continuously grow in your company’s advantage.
	          		</p>
	          		<p className="mt-3 aauxpro">
	          			For years, MSA has earned customers’ trust to provide solutions that meet each company’s unique human resources needs. Allow us to learn about your business and understand your challenges, and let us show you how we can be better together.
	          		</p>
	          		<p className="mt-3 aauxpro font-weight-bold">
	          			MSA. Your business partner in HR Solutions.
	          		</p>
	          	</div>
	          </div>
	        </div>
				</div>
			</div>
			<div className="ourvalues text-center" id="values">
				<h2 className="display-5 mb-5 josefinsans font-weight-bold">Our <span className="wow">Values</span></h2>
				<div className="row d-flex justify-content-center">
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-3">
								<img src={ImgTrustworthy} alt="Trustworthy" />
								<div className="mt-3 pl-3 pr-3">
									<p className="title josefinsans font-weight-bold">
										A Service You Can Trust
									</p>
									<p className="aauxpro">
										Our years of experience in HR makes us the reliable solution to your human capital issues
									</p>
								</div>
							</div>
							<div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-3">
								<img src={ImgProfessional} alt="Professional" />
								<div className="mt-3 pl-3 pr-3">
									<p className="title josefinsans font-weight-bold">
										Value in Professionalism
									</p>
									<p className="aauxpro">
										We operate with integrity and professionalism, committed to serve you with the highest quality of service.
									</p>
								</div>
							</div>
							<div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-3">
								<img src={ImgFastRespond} alt="Fast Respond" />
								<div className="mt-3 pl-3 pr-3">
									<p className="title josefinsans font-weight-bold">
										Always Fast Respond
									</p>
									<p className="aauxpro">
										We highly value your time. MSA’s team is right by your side to quickly address your inquiries.
									</p>
								</div>
							</div>
							<div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-3">
								<img src={ImgSimple} alt="Simple" />
								<div className="mt-3 pl-3 pr-3">
									<p className="title josefinsans font-weight-bold">
										One goal, one team, one family
									</p>
									<p className="aauxpro">
										We are committed in working together, side-by-side, to meet YOUR goals.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> 
			<div className="ourteam text-center bg-white" id="team">
				<h2 className="display-5 mb-5 josefinsans font-weight-bold">Our <span className="wow">Team</span></h2>
				<div className="row d-flex justify-content-center">
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<div className="row d-flex justify-content-center text-left">
							<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 mb-3">
								<img src={ImgImam} alt="Imam Prakoso" />
								<div className="team">
									<p className="title josefinsans font-weight-bold">
										Imam Prakoso
									</p>
									<p className="role text-left josefinsans">
										SAP HCM & Sf Senior Functional Consultant
									</p>
								</div>
							</div>
							<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 mb-3">
								<img src={ImgAndi} alt="Andi Sulistianto" />
								<div className="team">
									<p className="title josefinsans font-weight-bold">
										Andi Sulistianto
									</p>
									<p className="role text-left josefinsans">
										SAP HCM & Sf Senior Techno Functional Consultant
									</p>
								</div>
							</div>
							<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 mb-3">
								<img src={ImgArnold} alt="Arnold Silaban" />
								<div className="team">
									<p className="title josefinsans font-weight-bold">
										Arnold Silaban
									</p>
									<p className="role text-left josefinsans">
										SAP HCM Techno Functional Consultant
									</p>
								</div>
							</div>
							<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 mb-3">
								<img src={ImgFauzi} alt="Fauzi Adima R" />
								<div className="team">
									<p className="title josefinsans font-weight-bold">
										Fauzi Adima R
									</p>
									<p className="role text-left josefinsans">
										SAP HCM Consultant
									</p>
								</div>
							</div>
							<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 mb-3">
								<img src={ImgPanji} alt="Panjinagara Vandy F." />
								<div className="team">
									<p className="title josefinsans font-weight-bold">
										Panjinagara Vandy F.
									</p>
									<p className="role text-left josefinsans">
										SAP HCM Junior Consultant
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center">
						<div className="col-xs-10 col-sm-10 col-md-8 col-lg-8 col-xl-6 text-center aauxpro mt-5 mb-5">
							Hello there! The MSA team consists of highly passionate professionals with backgrounds in Human Resources who are working towards the same goal—to satisfy our client’s HRIS needs through hard work and dedication.
						</div>
					</div>
				</div>
			</div>
    </div>
  );
}

export default About;