import React from 'react';
import './Career.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown  } from '@fortawesome/free-solid-svg-icons'

function Career() {

  return (
    <div className="career">
    	<div className="greeter d-flex justify-content-center align-items-center mx-auto text-center" id="goals">
    		<div className="col-12">
				  <h1 className="display-4 josefinsans font-weight-bold"><span className="wow">Join</span> Our Team</h1>
				  <div className="lead d-flex justify-content-center">
				  	<div className="col-xs-11 col-sm-10 col-md-8 col-lg-6 col-xl-6 mb-2 text-center aauxpro">
				  		Passionate in human resources informational system?<br/>
				  		Contact us for career opportunities.<br/><br/>
				  		Let’s work together to make things better.
				  	</div>
				  </div>
			  </div>
			</div>
			<div className="positions bg-white" id="position">
				<div className="d-flex justify-content-center">
					<div className="box col-xs-12 col-sm-10 col-md-10 col-lg-10">
						<h2 className="display-5 mb-5 josefinsans font-weight-bold"><span className="wow">Here</span> are some position we are currently seeking to fill:</h2>
						<div className="aauxpro position mb-3 p-3">
							<div className="title font-weight-bold">
								<a href="#vacancy" data-toggle="collapse" aria-expanded="false">
									<div>
										Vacancy
										<i className="up float-right"><FontAwesomeIcon icon={faCaretUp} /></i>
        						<i className="down float-right"><FontAwesomeIcon icon={faCaretDown} /></i>
									</div>
								</a>
							</div>
							<div className="collapse desc bg-white p-4" id="vacancy">
							There are currently no vacancies available. However, we are always excited to meet enthusiastic and talented professionals who would like to join our team. If you wish to be considered for any future positions, you can still fill up the form below.
							</div>
						</div>
						<div className="aauxpro position mb-3 p-3">
							<div className="title font-weight-bold">
								<a href="#internship" data-toggle="collapse" aria-expanded="false">
									<div>
										Internship
										<i className="up float-right"><FontAwesomeIcon icon={faCaretUp} /></i>
        						<i className="down float-right"><FontAwesomeIcon icon={faCaretDown} /></i>
									</div>
								</a>
							</div>
							<div className="collapse desc bg-white p-4" id="internship">
							There are currently no internship available. However, we are always excited to meet enthusiastic and talented individuals who would like to join our team. If you wish to try and explore more experiences, you can still fill up the form below.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="interested-form">
				<div className="row d-flex justify-content-center">
					<div className="row col-xs-12 col-sm-11 col-md-10 col-lg-9 col-xl-8">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-white">
							<h2 className="display-5 mb-5 josefinsans font-weight-bold">Interested to Join Our Team?<br/><br/> Email Us!</h2>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 aauxpro">
							<form action="mailto:admin@msa-id.com?Subject=Hello MSA, I Wanna this Position" method="post" encType="text/plain">
								<div className="form-group">
									<label htmlFor="form-fullname" className="text-white">Full Name</label>
									<input type="text" id="form-fullname" className="form-control" placeholder="Fill your full name here" name="name"/>
								</div>
								<div className="form-group">
									<label htmlFor="form-email" className="text-white">Email</label>
									<input type="email" id="form-email" className="form-control" placeholder="Fill your email here" name="email"/>
								</div>
								<div className="form-group">
									<label htmlFor="form-phone" className="text-white">Phone Number</label>
									<input type="tel" id="form-phone" className="form-control" placeholder="Fill your phone number here" name="phone"/>
								</div>
								<div className="form-group">
									<label htmlFor="form-position" className="text-white">Desire Position</label>
									<input type="text" id="form-position" className="form-control" placeholder="Fill your desire position here" name="desire-position"/>
								</div>
								<div className="form-group">
									<label htmlFor="form-personal" className="text-white">Portfolio/Personal Website</label>
									<input type="text" id="form-personal" className="form-control" placeholder="Fill your portfolio/personal website here" name="portfolio-personal"/>
								</div>
								<div className="text-right">
									<button type="submit" className="btn bg-msa-yellow font-weight-bold">Send</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
    </div>
  );
}

export default Career;