import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact bg-white">
    	<div className="address d-flex justify-content-center text-justify">
    		<div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
					<div className="row pb-5">
						<iframe title="map" className="maps col-xs-12 col-sm-12 col-md-12 col-lg-7" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d721.6870385642287!2d106.80534275870261!3d-6.229664765891615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6e654c3d9fa174e0!2svOffice+-+Office+8!5e0!3m2!1sen!2sid!4v1566492667435!5m2!1sen!2sid" frameBorder="0" allowFullScreen></iframe>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 address-box aauxpro">
							<div className="manaser col-xs-12 col-sm-10 col-md-10 col-lg-10">
								<h1 className="display-6 mb-4 text-blue font-weight-bold josefinsans">Contact Us.</h1>
								<p className="mb-4">
									Office 8, level 18-A <br/>
									Jl. Jend Sudirman Kav 52-53 Sudirman Central Business District (SCBD) South Jakarta 12190 
								</p>
								<p>
									<strong>T</strong> +62 812 8403 8431
								</p>
								<p>
									<strong>E</strong> admin@msa-id.com
								</p>
								<a href="https://api.whatsapp.com/send?phone=6281284038431&text=Hi MSA" className="btn btn-success mr-2 mb-2 aauxpro">Whatsapp</a>
								<a className="btn btn-light mr-2 mb-2 aauxpro" href="mailto:admin@msa-id.com?Subject=Hello%20MSA" target="_top">Email</a>
								<a className="btn btn-info mr-2 mb-2 aauxpro" href="https://www.google.com/maps/dir/Current+Location/vOffice - Office 8, SCBD lot 8, Office 8, Jl. Jend. Sudirman No.Kav.52-53">Map Direction</a>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
  );
}

export default Contact;