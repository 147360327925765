import React from 'react';
import { Link } from 'react-router-dom';
import ImgMsaInfo from '../images/logo-msa-info.png';
import './Footer.css';

function Footer() {
  return (
  	<div className="footer bg-white aauxpro">
  		<div className="row info">
	      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mb-3">
	      	<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
	      		<img className="img-fluid" src={ImgMsaInfo} alt="msa-logo"/>
	      	</div>
	      	<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 address">
		      	<div>Office 8, level 18-A</div>
		      	<div>Jl. Jendral Sudirman Kav 52-53</div>
		      	<div>Sudirman Central Business</div>
		      	<div>District SCBD</div>
		      	<div>South Jakarta 12190</div>
	      	</div>
	      </div>
	      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9">
					<div className="row d-flex">
						<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
							<Link className="nav-link font-weight-bold" to='/'>Home</Link>
							<a className="nav-link small" href='/#portfolio'>Our Portfolio</a>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
							<Link className="nav-link font-weight-bold" to='/about'>About Us</Link>
							<a className="nav-link small" href='/about#goals'>Our Goals</a>
							<a className="nav-link small" href='/about#values'>Our Values</a>
							<a className="nav-link small" href='/about#team'>Our Team</a>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
							<Link className="nav-link font-weight-bold" to='/services'>Services</Link>
							<a className="nav-link small" href='/services#managed-service'>Managed Service</a>
							<a className="nav-link small" href='/services#implementation'>Implementation</a>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
							<Link className="nav-link font-weight-bold" to='/career'>Career</Link>
							<a className="nav-link small" href='/career#position'>Vacancy</a>
							<a className="nav-link small" href='/career#position'>Internship</a>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
							<Link className="nav-link font-weight-bold" to='/contact'>Contact Us</Link>
						</div>
					</div>
	      </div>
	    </div>
	    <div className="text-right">
	      &copy; 2020 MSA, All Rights Reserved
	    </div>
    </div>
  );
}

export default Footer;